import React from "react";

import { HeadingLevel, Heading } from "baseui/heading";
import { Paragraph1, Label1 } from "baseui/typography";
import { StyledLink } from "baseui/link";

import Page from "../../components/internal-page";

const Reports = () => {
  return (
    <Page title="Отчеты Фонда" path="/fund/reports" parent="fund">
      <HeadingLevel>
        <Heading>2021</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1AMWlzaLNF4zAwZalQKdkQPVx0Xomzsc6/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудиторское заключение
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1cZsth4a9YdX1084Z1Ba3hS5-cBeR5FdW/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 02.03.2022</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2020</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1OMG5i2s436V7jNvJCsocxJuXr8vD2ylI/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудиторское заключение
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1CnwLCtsp5TuGWt7vzku7fWwvqnlkyieu/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 26.03.2021</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2019</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1ZRBWlXAwcO7FlOmld01CeHEcn9NiWWXJ/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудиторское заключение
          </StyledLink>
        </Paragraph1>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/11XMdB2wCj5sQY4Rtl5EGOi7s766G8iJq/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 17.02.2020</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2018</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1NEu8W-eUlIOC4s8w6MxOFM2_5CKnRR1A/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудит и бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 22.03.2019</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2017</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1KNwPGw-B26ozgQtYF4-TSdAfqLxDZiCM/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудит и бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 06.03.2018</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2016</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/12et-pR8QWFFjsvljEJsffRh5RiB4u42V/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудит и бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 15.03.2017</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2015</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1zg7ObCKgQdkBIyszhenX2o4facG5D0iA/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Аудит и бухгалтерский баланс
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 28.03.2016</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2014</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/15Y9G3X532FNcaIKdI1dxL2KmAaT0QwXG/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Архив с отчетами
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 10.03.2015</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2013</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1hZ5Sm1rgqDc7ImTdpxDEjqOmdqBuHBj1/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Архив с отчетами
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 03.04.2014</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2012</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1YApdIRUjzRzd-9a3vjIBJM8sdzxlK8Wn/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Архив с отчетами
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 28.03.2013</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2011</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1g0SZPDR6uvy6lQ_mrl2SIcCm7qcvpHzy/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Отчеты
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 30.05.2012</Label1>
      </HeadingLevel>
      <HeadingLevel>
        <Heading>2010</Heading>
        <Paragraph1>
          <StyledLink
            href="https://drive.google.com/file/d/1AL8QlFS59-kZFEBErtJOqoW9rhR8tmyC/view"
            target="_blank"
            rel="noopener noreferrer"
          >
            Отчеты
          </StyledLink>
        </Paragraph1>
        <Label1>Опубликован 30.05.2011</Label1>
      </HeadingLevel>
    </Page>
  );
};

export default Reports;
